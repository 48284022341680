import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { _PurchaseCourse } from "../../../Services/ProductManager";
import {
  ActivePack,
  IsLoading,
  User,
} from "../../../Containers/Redux/Selectors";

function Products({ product }) {
  const user = User();
  const isLoading = IsLoading();
  const activePack = ActivePack();
  const [purchased, setPurchased] = useState(false);

  useEffect(() => {
    setPurchased(
      activePack?.filter((state) => state?.course_id == product?._id)[0]
    );
  }, []);
  return (
    <div className="w-1/3 my-4 px-3 min-h-0 rounded-lg py-0 max-[950px]:w-1/2 max-[620px]:w-full">
      <div className="w-full bg-white border border-black rounded-lg">
        <div className="w-full flex items-center flex-col relative">
          <div className="mt-4 mx-1 w-full px-4">
            <div className="text-xl font-bold px-2 pt-3 text-black/90">
              {product.product_name}
            </div>
            <div className="text-2xl font-semibold px-2 pb-3 pt-1 text-black">
              ₹{product.discount_pricing}{" "}
              <span className="text-sm text-black/90">
                / {product.total_session} sessions
              </span>
            </div>
          </div>
          <div className="px-4 w-full">
            <ul className="w-full">
              <li className="flex items-start my-3 w-full">
                <div className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
                  <AiOutlineCheck color="black" fontSize={14} />
                </div>
                <span className="ml-2 w-4/5 text-base text-black/90">
                  Practice with Industry mentors
                </span>
              </li>
              <li className="flex items-start my-3 w-full">
                <div className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
                  <AiOutlineCheck color="black" fontSize={14} />
                </div>
                <span className="ml-2 w-4/5 text-base text-black/90">
                  Personalized feedback for each session
                </span>
              </li>
              <li className="flex items-start my-3 w-full">
                <div className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
                  <AiOutlineCheck color="black" fontSize={14} />
                </div>
                <span className="ml-2 w-4/5 text-base text-black/90">
                  Get Insight into your academics
                </span>
              </li>
              <li className="flex items-start my-3 w-full">
                <div className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
                  <AiOutlineCheck color="black" fontSize={14} />
                </div>
                <span className="ml-2 w-4/5 text-base text-black/90">
                  Get Insight on the modules you need to prepare for clear HR
                  and Technical rounds
                </span>
              </li>
              <li className="flex items-start my-3 w-full">
                <div className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
                  <AiOutlineCheck color="black" fontSize={14} />
                </div>
                <span className="ml-2 w-4/5 text-base text-black/90">
                  AI tool to give you complete behaviour analysis
                </span>
              </li>
            </ul>
          </div>
          <div className="w-full flex items-center py-4 justify-center">
            {isLoading ? (
              <div className="bg-white h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg">
                Loading....
              </div>
            ) : (
              <>
                {product.activate ? (
                  <>
                    {purchased ? (
                      <button
                        onClick={() => {
                          window.location.assign(`/dashboard`);
                        }}
                        className="border-2  text-white border-white cursor-pointer h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg"
                      >
                        Go To Course
                      </button>
                    ) : (
                      <div
                        onClick={() => _PurchaseCourse(product, user)}
                        className="bg-black text-white cursor-pointer h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg"
                      >
                        Enroll Now
                      </div>
                    )}
                  </>
                ) : (
                  <div className="bg-[#f7dc6f] border-2 border-black h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg">
                    Coming Soon
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
