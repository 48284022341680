import React, { useState } from "react";
import validator from "validator";
import { _apiCalls } from "../../../../Services/ApiCalls";
import { useCookies } from "react-cookie";

function Signup() {
  const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const _handleSubmit = async () => {
    try {
      let data = {
        name: name,
        phone: phone,
        email: email,
        password: password,
      };
      const request = await _apiCalls("/affiate/signup", data);
      setCookie("jwt", request.token);
    } catch (e) {}
  };
  return (
    <div className="w-full flex justify-center bg-white min-h-screen">
      <div className="w-1/2">
        <div className="py-10 px-16 flex items-center w-full justify-between">
          <span className="font-bold text-2xl flex items-center text-black">
            Skillkart.app
          </span>
        </div>
        <div className="py-0 px-24">
          <div className="text-6xl font-bold text-black">
            Scale your Business
          </div>
          <div className="text-sm mt-3 text-black/80">
            Welcome to our Affiliate Program! Whether you're an individual or a
            company, we're excited to have you join our network.
          </div>
          <div className="my-6">
            <div className="text-black/90 text-base font-medium mt-3">Name</div>
            <div className="border border-black/60 h-12">
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="outline-none text-black px-4 bg-transparent h-full w-full"
              />
            </div>
            <div className="text-black/90 font-medium text-base mt-3">
              Enter email address
            </div>
            <div className="border border-black/60 h-12">
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="outline-none text-black px-4 bg-transparent h-full w-full"
              />
            </div>
            <div className="text-black/90 font-medium text-base mt-3">
              Phone Number
            </div>
            <div className="border border-black/60 h-12">
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                className="outline-none text-black px-4 bg-transparent h-full w-full"
              />
            </div>
            <div className="text-black/90 font-medium text-base mt-3">
              Create an password
            </div>
            <div className="border border-black/60 h-12">
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="outline-none text-black px-4 bg-transparent h-full w-full"
              />
            </div>
            <div
              onClick={_handleSubmit}
              className="bg-black text-[#f4d03f] border border-black mt-6 py-3 text-center rounded-md capitalize font-semibold"
            >
              continue
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-screen p-4">
        <div className="w-full h-full bg-black rounded-lg"></div>
      </div>
    </div>
  );
}

export default Signup;
