import { lazy } from "react";

export const Home = lazy(() => import("../Pages/Home"));
export const Blog = lazy(() => import("../Pages/Blog/Blog"));
export const About = lazy(() => import("../Pages/AboutUs"));
export const ContactUs = lazy(() => import("../Pages/Contact"));
export const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
export const RefundPolicy = lazy(() => import("../Pages/RefundPolicy"));
export const Terms = lazy(() => import("../Pages/Terms"));
export const PaymentComplete = lazy(() => import("../Pages/PaymentDone"));
export const JobDetail = lazy(() => import("../Pages/Jobs/JobDetail"));
export const Jobs = lazy(() => import("../Pages/Jobs/Jobs"));
export const BlogDetails = lazy(() => import("../Pages/Blog/BlogDetail"));

// Auth links
export const Login = lazy(() => import("../Pages/Auth/Login"));
export const Signup = lazy(() => import("../Pages/Auth/Signup"));
export const ForgotPassword = lazy(() => import("../Pages/Auth/PasswordReset"));
export const OnBoardingStudent = lazy(() =>
  import("../Pages/Auth/OnBoarding/Student/StudenOnboarding")
);

export const Programs = lazy(() => import("../Pages/Programs/Programs"));

// userLinks

export const UserDashBoard = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Dashboard")
);

export const UserProfile = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Profile/UserProfile")
);

export const UserTranscation = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Transcations/Purchase")
);
export const UserReference = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Referals/Referals")
);
export const MentorSelection = lazy(() =>
  import("../Pages/Dashboard/UserPanel/SessionsAndBooking/MentorSelection")
);

export const SessionRoom = lazy(() => import("../Pages/Room/Room"));

export const BecomeMentor = lazy(() => import("../Pages/Auth/BecomeMentor"));

export const MentorOnBoarding = lazy(() =>
  import("../Pages/Auth/OnBoarding/Mentor/MentorOnboading")
);
export const MentorHomePage = lazy(() =>
  import("../Pages/Dashboard/MentorPanel/MentorHome")
);
export const MentorProfile = lazy(() =>
  import("../Pages/Dashboard/MentorPanel/Profile/Profile")
);
export const MentorSlots = lazy(() =>
  import("../Pages/Dashboard/MentorPanel/ManageSlots/ManageSlots")
);

export const UserFeedbackDetail = lazy(() =>
  import("../Pages/Dashboard/UserPanel/SessionFeedback/UserFeedback")
);
export const MentorSession = lazy(() =>
  import("../Pages/Dashboard/MentorPanel/Sesssions/MentorSessions")
);
export const UserPerformance = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Performance/Performance")
);
export const UserSession = lazy(() =>
  import("../Pages/Dashboard/UserPanel/Sessions/UserSessions")
);
export const RoomFeedback = lazy(() => import("../Pages/Room/RoomFeedback"));

export const PasswordReset = lazy(() => import("../Pages/Auth/PasswordReset"));
export const Loading = lazy(() => import("../Components/Common/Loading"));
export const BlogSearch = lazy(() => import("../Pages/Blog/BlogSearch"));
export const PaymentDone = lazy(() => import("../Pages/PaymentDone"));

// adminroutes

export const AdminAuth = lazy(() => import("../Pages/Admin/Auth/Login"));
export const AdminMentor = lazy(() =>
  import("../Pages/Admin/Dashboard/Mentor/Mentor")
);
export const AdminStudent = lazy(() =>
  import("../Pages/Admin/Dashboard/Student/Student")
);
export const AdminProduct = lazy(() =>
  import("../Pages/Admin/Dashboard/Product/Products")
);

export const AdminJobs = lazy(() =>
  import("../Pages/Admin/Dashboard/Jobs/Jobs")
);
