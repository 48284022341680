import React from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { _scrolltoElement } from "../../Script/OtherFunction";

function Footer() {
  return (
    <div class="">
      <div className="px-40 bg-[#f9f9fa] max-lg:px-10">
        <div className="w-full min-h-20  pt-14 pb-6   flex items-start justify-between flex-wrap">
          <div className="max-[800px]:w-1/2 max-[500px]:w-full">
            <div className="font-bold text-lg">Assessment</div>
            <div className="py-4">
              <div
                onClick={() => {
                  _scrolltoElement("Assessments");
                }}
                className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black"
                style={{
                  fontWeight: 300,
                }}
              >
                Resume analysis
              </div>
              <div
                onClick={() => {
                  _scrolltoElement("Assessments");
                }}
                className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black"
              >
                Behaviour skill analysis
              </div>
              <div
                onClick={() => {
                  _scrolltoElement("Assessments");
                }}
                className=" font-extralight text-sm text-black/70 mt-3 cursor-pointer hover:text-black"
              >
                Technical skill analysis
              </div>
            </div>
          </div>

          <div className="max-[800px]:w-1/2 max-[500px]:w-full">
            <div className="font-semibold text-lg">Programs</div>
            <div className="py-4">
              <Link to="/Programs">
                <div
                  className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black"
                  style={{
                    fontWeight: 300,
                  }}
                >
                  B. Tech
                </div>
              </Link>
              <Link to="/Programs">
                <div className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black">
                  MBA
                </div>
              </Link>
              <Link to="/Programs">
                <div className=" font-extralight text-sm text-black/70 mt-3 cursor-pointer hover:text-black">
                  Web 3.0
                </div>
              </Link>
              <Link to="/Programs">
                <div className=" font-extralight text-sm text-black/70 mt-3 cursor-pointer hover:text-black">
                  Other technical field
                </div>
              </Link>
            </div>
          </div>
          <div className="max-[800px]:w-1/2 max-[500px]:w-full">
            <div className="font-semibold text-lg">Company</div>
            <div className="py-4">
              <Link to="/Blogs">
                <div
                  onClick={() => {
                    window.location.assign("/blogs");
                  }}
                  className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black"
                  style={{
                    fontWeight: 300,
                  }}
                >
                  Blogs
                </div>
              </Link>
              <div
                onClick={() => {
                  window.location.assign("/becomeamentor");
                }}
                className=" font-extralight text-sm text-black/70 mb-3 cursor-pointer hover:text-black"
              >
                Join as mentor
              </div>
              <div
                onClick={() => {
                  window.location.assign("/jobs");
                }}
                className=" font-extralight text-sm text-black/70 mt-3 cursor-pointer hover:text-black"
              >
                Jobs
              </div>
            </div>
          </div>
          <div className="flex items-center flex-col max-[800px]:w-1/2 max-[500px]:w-full">
            <div className="w-full">
              <div className="font-extralight text-sm">Follow us at</div>
              <div className="py-3 flex items-center">
                <Link
                  to="https://www.facebook.com/skillkart.appM"
                  target="_blank"
                >
                  <div className="flex items-center mx-2 justify-center  rounded-full cursor-pointer">
                    <FaFacebookF fontSize={16} color="rgba(0,0,0,0.8)" />
                  </div>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/skillkart-app/about/"
                  target="_blank"
                >
                  <div className="flex items-center mx-2 justify-center  rounded-full cursor-pointer">
                    <FaLinkedinIn fontSize={16} color="rgba(0,0,0,0.8)" />
                  </div>
                </Link>
                <Link
                  to="https://www.instagram.com/skillkart.app/"
                  target="_blank"
                >
                  <div className="flex items-center mx-2 justify-center  rounded-full  cursor-pointer">
                    <FaInstagram fontSize={16} color="rgba(0,0,0,0.8)" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-black/20 border-t pb-4 pt-4 flex items-center justify-between">
          <div className="flex items-center ">
            <a href="/Privacypolicy" target="_black">
              <div className="text-xs font-seminbold border-r-black border-r px-3 cursor-pointer">
                Privacy Policy
              </div>
            </a>
            <a href="/terms" target="_black">
              <div className="text-xs font-seminbold border-r-black border-r px-3 cursor-pointer">
                Terms & conditions
              </div>
            </a>
            <a href="/refundpolicy" target="_black">
              <div className="text-xs font-seminbold border-r-black border-r px-3 cursor-pointer">
                refund policy
              </div>
            </a>
            <a href="/aboutus" target="_blank">
              <div className="text-xs font-seminbold border-r-black border-r px-3 cursor-pointer">
                About us
              </div>
            </a>
            <div
              onClick={() => {
                window.location.assign("/in/faq");
              }}
              className="text-xs font-seminbold border-r-black border-r px-3 cursor-pointer"
            >
              Faqs
            </div>
          </div>
          <div className="flex items-center text-xs">
            <AiOutlineCopyrightCircle /> 2023 Skillkart.app
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
