import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDzFlCkKFeG-o-H41o94qaN6hZxkFzRPek",
  authDomain: "skillkartnewapp.firebaseapp.com",
  projectId: "skillkartnewapp",
  storageBucket: "skillkartnewapp.appspot.com",
  messagingSenderId: "99145903215",
  appId: "1:99145903215:web:db5c9b5576e5e67cc9319a",
  measurementId: "G-W952FF0150",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const analytics = getAnalytics(app);
const storage = getStorage();

const provider = new GoogleAuthProvider();
const auth = getAuth();

export { app, analytics, storage, auth, provider, db };
