import React from "react";
import { AiOutlineSwapRight } from "react-icons/ai";
import { Link } from "react-router-dom";

function Carreer() {
  return (
    <div
      className="py-24 px-16 flex items-center bg-[white] job-container"
      style={{
        fontFamily: `'Rubik', sans-serif`,
      }}
    >
      <div className="w-2/4 j-r-container">
        <div className="uppercase font-thin text-sm">career</div>
        <div className="my-2 text-2xl">Find your career option as</div>
        <div className="w-full py-6 flex  flex-wrap justify-between job-clm">
          <div className="w-1/2 pr-3 job-clm-row">
            <Link to="/Jobs">
              <div className="text-black font-semibold text-2xl mb-1 underline">
                Front end developer
              </div>
            </Link>
            <div className="text-justify">
              your role is to create the user-facing interface of a website or
              web application. You are responsible for designing and
              implementing the visual and interactive elements that users see
              and interact with on their devices
            </div>
          </div>
          <div className="w-1/2 pl-3 job-clm-row">
            <Link to="/Jobs">
              <div className="text-black font-semibold text-2xl mb-1 underline">
                Back end developer
              </div>
            </Link>
            <div>
              your primary focus is on the server-side of web development. You
              work behind the scenes to build and maintain the logic, database
              interactions, and APIs that power web applications.
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end mt-4 w-full">
          <Link to="/Jobs">
            <div
              className="shadow-md relative bg-[#f9f9fa] flex items-center "
              style={{
                width: 230,
                height: 50,
                borderRadius: 50,
              }}
            >
              <div className="px-6">Find out more</div>
              <div
                className="absolute right-0 top-0 bottom-0 flex items-center justify-center rounded-full"
                style={{
                  height: 50,
                  width: 50,
                  background: "black",
                }}
              >
                <AiOutlineSwapRight color="white" fontSize={20} />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-1/2 flex items-center justify-center job-container-image">
        <div
          className=""
          style={{
            width: 370,
            height: 370,
          }}
        >
          <img
            className="w-full h-full object-cover rounded-2xl"
            src="https://images.pexels.com/photos/2422294/pexels-photo-2422294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="job-offer"
          />
        </div>
      </div>
    </div>
  );
}

export default Carreer;
