import React, { Suspense } from "react";
import { Route, Routes } from "react-router";
import {
  About,
  AdminAuth,
  AdminJobs,
  AdminMentor,
  AdminProduct,
  AdminStudent,
  BecomeMentor,
  Blog,
  BlogDetails,
  BlogSearch,
  ContactUs,
  Home,
  JobDetail,
  Jobs,
  // Loading,
  Login,
  MentorHomePage,
  MentorOnBoarding,
  MentorProfile,
  MentorSelection,
  MentorSession,
  MentorSlots,
  OnBoardingStudent,
  PasswordReset,
  PaymentDone,
  PrivacyPolicy,
  Programs,
  RefundPolicy,
  RoomFeedback,
  SessionRoom,
  Signup,
  Terms,
  UserDashBoard,
  UserFeedbackDetail,
  UserPerformance,
  UserProfile,
  UserReference,
  UserSession,
  UserTranscation,
} from "./Links";
import Loading from "../Assets/images/Loading.svg";
import AffliateSignup from "../Pages/Affliate/Auth/Signup/Signup.jsx";
import FaqPage from "../Pages/Faq/Faq.jsx";
import NotFound from "../Pages/NotFound.js";
function RouteConfig() {
  return (
    <Suspense
      fallback={
        <div className="h-screen w-screen flex items-center justify-center">
          <img src={Loading} alt="" className="w-64" />
        </div>
      }
    >
      <Routes>
        <Route
          path="/home/affliate/becomeaffilate"
          element={<AffliateSignup />}
        />
        {/* admin Routes */}
        <Route exact path="/hq/admin/jobs" element={<AdminJobs />} />
        <Route exact path="/hq/admin/mentor" element={<AdminMentor />} />
        <Route exact path="/hq/admin/product" element={<AdminProduct />} />
        <Route exact path="/hq/admin/Student" element={<AdminStudent />} />
        <Route exact path="/hq/welcome/admin" element={<AdminAuth />} />
        {/*  */}
        <Route exact path="/jobs" element={<Jobs />} />
        <Route exact path="/Blogs" element={<Blog />} />
        <Route exact path="/Blog/:blog" element={<BlogDetails />} />
        <Route exact path="/Blog/search/" element={<BlogSearch />} />
        <Route exact path="/programs" element={<Programs />} />
        <Route exact path="/room/:roomid" element={<SessionRoom />} />
        {/* MentorPanel */}
        <Route exact path="/Dashboard/mentor/slots" element={<MentorSlots />} />
        <Route
          exact
          path="/Dashboard/mentor/profile"
          element={<MentorProfile />}
        />
        <Route exact path="/Dashboard/mentor" element={<MentorHomePage />} />
        <Route
          exact
          path="/Dashboard/mentor/sessions"
          element={<MentorSession />}
        />
        <Route exact path="/room/Feedback/:roomid" element={<RoomFeedback />} />
        {/* UserDashBoard */}
        <Route
          exact
          path="/Dashboard/feedback/:roomid/:mentorid"
          element={<UserFeedbackDetail />}
        />
        <Route
          exact
          path="/Dashboard/selectmentor/:sessiontype/:mentorid"
          element={<MentorSelection />}
        />
        <Route exact path="/Dashboard/referals" element={<UserReference />} />
        <Route
          exact
          path="/Dashboard/performance"
          element={<UserPerformance />}
        />
        <Route
          exact
          path="/Dashboard/student/sessions"
          element={<UserSession />}
        />
        <Route
          exact
          path="/Dashboard/user/transcation"
          element={<UserTranscation />}
        />
        <Route
          exact
          path="/account/student/profile"
          element={<UserProfile />}
        />
        <Route exact path="/dashboard" element={<UserDashBoard />} />
        {/*  */}
        {/* Auth Links */}
        <Route exact path="/becomeamentor" element={<BecomeMentor />} />
        <Route
          exact
          path="/Account/mentor/onboarding/"
          element={<MentorOnBoarding />}
        />
        <Route
          exact
          path="/Account/student/onboarding/"
          element={<OnBoardingStudent />}
        />
        <Route
          exact
          path="/Account/forgetpassword"
          element={<PasswordReset />}
        />
        <Route exact path="/account/signup" element={<Signup />} />
        <Route exact path="/account/login" element={<Login />} />
        {/*  */}
        {/* Home */}
        <Route exact path="/in/faq" element={<FaqPage />} />
        <Route exact path="/jobs/detail" element={<JobDetail />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/refundpolicy" element={<RefundPolicy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/Privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/aboutus" element={<About />} />
        <Route exact path="/paymentSuccessfull" element={<PaymentDone />} />
        <Route exact path="/" element={<Home />} />
        {/*  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default RouteConfig;
