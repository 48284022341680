import { signInWithPopup } from "firebase/auth";
import { _apiCalls, _apiGetCalls } from "./ApiCalls";
import { Errorhandler } from "./ErrorHandler";
import api from "./api";
import { auth, provider } from "../Containers/Firebase/Firebase";

export const _isUserLoggedIn = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request = await api.get("/isLoggedIn", {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "true",
        },
      });
      resolve(request.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const validateCode = (codeTime, dispatch, otp, code) => {
  if (otp != code) {
    dispatch({
      type: "site_Errors",
      payload: {
        error: true,
        errormessage: "Invalid Otp",
      },
    });
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    return setTimeout(() => {
      dispatch({
        type: "site_Errors",
        payload: {
          error: false,
        },
      });
    }, 3000);
  }
  const timeNow = new Date().toLocaleTimeString();

  // Previous time (code time), assuming it's in the format "hh:mm:ss"

  // Parse the timestamps into Date objects
  const currentTime = new Date();
  const codeTimeDate = new Date(`${currentTime.toDateString()} ${codeTime}`);

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(currentTime - codeTimeDate);

  // Convert the time difference from milliseconds to minutes
  const minutes = Math.floor(timeDifference / 60000);

  // Check if the time difference is less than or equal to 5 minutes
  if (minutes <= 5) {
    // If the difference is less than or equal to 5 minutes, dispatch actions

    // Dispatch actions
    dispatch({ type: "verifyCode", payload: false });
    dispatch({ type: "hasUserAccount", payload: false });
  } else {
    console.log("Time difference is greater than 5 minutes.");
  }
};
export const _handleEmailVerification = async (data, dispatch) => {
  dispatch({ type: "site_Loading", payload: true });
  try {
    await _apiGetCalls(`/becomeMentor?emailId=${data.email}&otp=${data.otp}`);
    dispatch({ type: "site_Loading", payload: false });
    dispatch({
      type: "verifyCode",
      payload: true,
    });
    return;
  } catch (error) {
    dispatch({
      type: "verifyCode",
      payload: false,
    });
    dispatch({ type: "site_Loading", payload: false });
    Errorhandler("User already has an account", dispatch);
  }
};

export const _userLogout = async (removeCookie) => {
  removeCookie("jwt");
  setTimeout(() => {
    window.location.replace("/");
  }, 1000);
};
const _handleDispatchDate = async (data, setCookie, dispatch) => {
  try {
    const request = await _apiCalls("/socialauth", data);
    if (request.data.user.completed) {
      setCookie("jwt", request.token, { path: "/" });
      return window.location.assign("/dashboard");
    }
    return window.location.assign(
      `/account/student/onboarding?Authorize_token=${request.token}`
    );
  } catch (err) {
    const message =
      err?.response?.data?.message || "Please try after some time.";
    Errorhandler(message, dispatch);
  }
};
export const handleGoogleSignUp = (cookie, dispatch) => {
  dispatch({ type: "site_Loading", payload: true });
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const data = result.user.providerData[0];
      await _handleDispatchDate(data, cookie, dispatch);
      dispatch({ type: "site_Loading", payload: false });
    })
    .catch((error) => {
      dispatch({ type: "site_Loading", payload: false });
    });
};
