import React from "react";

function SiteBenefit() {
  return (
    <div className="p-3 mt-8 mb-12">
      <div class="whus pt-24 pb-6 w-full bg-black rounded-3xl">
        <div className="w-full flex items-center flex-col">
          <div className="text-6xl text-center w-8/12 font-bold text-white max-sm:text-2xl max-md:text-4xl">
            Why Students Choose Our Program to Excel in Interview Preparation
          </div>
        </div>
        <div className="py-28 w-full flex items-start w_u_container">
          <div className="w-1/2 px-20 w_u_option_con">
            <div className="mb-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined text-5xl">
                  support_agent
                </span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                Rich Experienced Mentors
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                The mentor is typically more experienced or knowledgeable in the
                area in question and acts as a role model and sounding board for
                the mentee
              </div>
            </div>
            <div className="my-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined">videocam</span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                1:1 Quality Mock Interviews
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                The goal of a 1:1 mentorship is to help the mentee learn new
                skills, gain valuable insights, and achieve their goals.
              </div>
            </div>
            <div className="mt-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined">speed</span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                Value of Network and learning Over Money
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                a strong network and a focus on learning can also provide a
                sense of fulfilment and purpose that may be difficult to achieve
                through the pursuit of money alone.
              </div>
            </div>
          </div>
          <div className="w-1/2 px-20 w_u_option_con">
            <div className="mb-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined">paid</span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                Post-interview In-depth Feedback with suggestions
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                Providing post-interview in-depth feedback with suggestions can
                be a valuable tool for helping job candidates improve their
                interview skills and increase their chances of success in the
                future.
              </div>
            </div>
            <div className="my-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined">groups</span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                Network with mentors for future reference
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                Networking with mentors can be a valuable tool for building
                professional connections and gaining valuable guidance and
                advice.
              </div>
            </div>
            <div className="mt-10">
              <div className="w-12 h-12 text-[#f7dc6f] flex items-center justify-center rounded-md shadow-md">
                <span class="material-symbols-outlined">videocam</span>
              </div>
              <div className="mt-3 font-medium text-xl text-white">
                Backend Support
              </div>
              <div className="text-white/80 text-sm my-2 text-justify">
                We take pride in our robust backend support system. Our
                dedicated team is always available to assist you with any
                logistical or administrative matters, ensuring that your
                mentorship experience is smooth and hassle-free.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteBenefit;
