import React from "react";
import { useSelector } from "react-redux";
import { courses } from "../../../Data/dataArray";
import Products from "./Products";

function ProductShowCase() {
  const isloading = useSelector((state) => state.isFetchingData);

  return (
    <div className="bg-white pt-24 w-full flex items-center justify-center">
      <div className="w-11/12 max-[950px]:w-full">
        <div className="mx-4 flex flex-col items-center max-sm:mx-0">
          <div className="text-lg py-3 px-8 rounded-md bg-[#fff291] text-center font-bold">
            Programs we offering
          </div>
          <div className="text-5xl font-semibold w-9/12 my-4 text-black text-center max-[960px]:w-11/12 max-[960px]:text-3xl">
            {" "}
            Let Our Expert Guidance Propel You Towards Success in the
            Competitive Job Market.
          </div>

          <div className="flex items-center justify-center flex-wrap w-full py-8">
            {isloading ? (
              <div className=" w-full flex items-center my-4">
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
              </div>
            ) : (
              <>
                {courses?.map((product, index) => (
                  <Products product={product} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductShowCase;
