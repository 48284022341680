import { useSelector } from "react-redux";

export const IsLoading = () => useSelector((state) => state.isLoading);

export const User = () => useSelector((state) => state.user);

export const Products = () => useSelector((state) => state.Products);

export const IsFetchingData = () =>
  useSelector((state) => state.isFetchingData);

export const HasAccount = () => useSelector((state) => state.hasAccount);

export const Blogs = () => useSelector((state) => state.Blogs);

export const Error = () => useSelector((state) => state.error);

export const Transctions = () => useSelector((state) => state.UserTranscation);
export const HasActivePack = () => useSelector((state) => state.hasActivePack);
export const ActivePack = () => useSelector((state) => state.ActivePack);
export const UserSessions = () => useSelector((state) => state.userSessions);

export const GetMentors = () => useSelector((state) => state.mentors);
export const UserStreams = () => useSelector((state) => state.userPermissions);
export const UserFeedback = () => useSelector((state) => state.userFeedback);
export const OtherInfo = () => useSelector((state) => state.otherInfo);
export const OnboardingStep = () =>
  useSelector((state) => state.OnboardingStep);
