import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import RouteConfig from "./Routes/RouteConfig";
import { Suspense, useEffect } from "react";
import { _fetchProducts } from "./Services/ProductManager";
import { useDispatch } from "react-redux";
import { _isUserLoggedIn } from "./Services/AuthManager";
import { useCookies } from "react-cookie";
import "./styles/Global.css";
import { _GetMentor, _siteDatafetching } from "./Services/OtherManager";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "isFetchingData",
      payload: true,
    });

    _fetchProducts()
      .then((result) => {
        dispatch({
          type: "site_Products",
          payload: result,
        });
      })
      .catch(() => {
        return [];
      });
    _siteDatafetching()
      .then((result) => {
        const { product, blogs } = result;
        dispatch({
          type: "site_Blogs",
          payload: blogs,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (cookies.jwt) {
      _isUserLoggedIn(cookies.jwt)
        .then((result) => {
          dispatch({
            type: "currentUser",
            payload: result.data,
          });
          dispatch({
            type: "isFetchingData",
            payload: false,
          });
          dispatch({
            type: "otherInfo",
            payload: result.OtherInfo,
          });
          dispatch({
            type: "user_transcations",
            payload: result.transcation,
          });
          dispatch({
            type: "hasActivePack",
            payload: result.hasActivePack,
          });
          dispatch({
            type: "Active_pack",
            payload: result.ActivePack,
          });
          dispatch({
            type: "room_feedback",
            payload: result.feedback,
          });
          dispatch({
            type: "user_Sessions",
            payload: result.meeting,
          });
          _GetMentor(dispatch);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch({
        type: "isFetchingData",
        payload: false,
      });
      return;
    }
    dispatch({
      type: "isFetchingData",
      payload: false,
    });
  }, []);

  return (
    <Router>
      <RouteConfig />
    </Router>
  );
}

export default App;
