export const Errorhandler = (errorMessage, dispatch) => {
  dispatch({
    type: "site_Errors",
    payload: {
      error: true,
      errormessage: errorMessage,
    },
  });

  setTimeout(() => {
    dispatch({
      type: "site_Errors",
      payload: {
        error: false,
        errormessage: errorMessage,
      },
    });
  }, 3000);
  dispatch({ type: "site_Loading", payload: false });
};
