import React from "react";
import Banner from "../../Assets/images/homebaner - Copy.jpeg";
import { useDispatch } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineAmazon, AiOutlineMail } from "react-icons/ai";
import { SiAdobe } from "react-icons/si";
import { _apiCalls } from "../../Services/ApiCalls";
import { BsMicrosoft } from "react-icons/bs";
import { AiFillApple } from "react-icons/ai";
import { Link } from "react-router-dom";
import Header from "../Common/Header";
import ProductShowCase from "./Product/ProductShowCase";
import Explainer from "./Explainer";
import { signInWithPopup } from "firebase/auth";
import { provider, auth } from "../../Containers/Firebase/Firebase";
import { useCookies } from "react-cookie";
import { handleGoogleSignUp } from "../../Services/AuthManager";

function Homebanner() {
  const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);

  return (
    <div>
      <Header />
      <div className="items-center  flex justify-center bg-black rounded-lg mx-4 my-2">
        <div className="pt-28 pb-20 flex flex-col items-center max-[666px]:pt-16 max-[666px]:pb-10">
          <div className="font-semibold text-8xl text-white text-center w-9/12 max-[816px]:w-11/12 max-[666px]:text-start max-[666px]:px-2">
            <div className="max-[816px]:text-6xl max-sm:text-5xl">
              Be job interview ready within <span className="text-[#f4d03f]">30</span>{" "}
              days.
            </div>
          </div>
          <div className="text-white/70 text-xl  my-7 text-center px-16 w-9/12 max-[816px]:w-11/12 max-[666px]:text-start max-[666px]:px-2">
            Unlock Your Full Potential: Transform into a Confident and Competent
            Interviewee in Just 30 Days with Our Comprehensive Preparation
            Program!
          </div>
          <div className="flex items-center justify-center mt-6 mb-10 w-9/12 max-[666px]:flex-col max-[666px]:items-start max-[666px]:w-11/12">
            <Link to="/account/login">
              <div
                style={{
                  fontFamily: `'Rethink Sans', sans-serif`,
                }}
                className="mx-2 max-[666px]:mb-4 max-[666px]:rounded-md py-3 px-6 flex items-center border border-[#f4d03f] justify-center rounded-full cursor-pointer"
              >
                <div className="mx-2 text-lg font-bold  text-white">
                  Signin with Email
                </div>
              </div>
            </Link>
            {/* <Link to="/programs"> */}
            <div
              onClick={() => handleGoogleSignUp(setCookie)}
              className=" bg-white max-[666px]:mb-4 max-[666px]:rounded-md cursor-pointer tex-black mx-2 py-3 px-7 font-semibold text-lg flex items-center rounded-full"
            >
              <div className="mr-2">
                <FcGoogle fontSize={24} />
              </div>{" "}
              SignIn with Google
            </div>
            {/* </Link> */}
          </div>
          <div className=" w-9/12 max-[666px]:w-11/12 max-[666px]:px-4">
            <div className=" text-white/80">Learn from our top mentor</div>
            <div className="pt-5 flex items-center justify-start max-md:flex-wrap w-5/6">
              <div className="flex items-center justify-start ">
                <div className="pr-2 mr-2">
                  <FcGoogle fontSize={24} />
                </div>
                <div className="px-2 mx-2">
                  <AiOutlineAmazon color="white" fontSize={25} />
                </div>
                <div className="px-2 mx-2">
                  <SiAdobe color="red" fontSize={24} />
                </div>
                <div className="px-2 mx-2">
                  <BsMicrosoft color="#16a3dd" fontSize={21} />
                </div>
                <div className="px-2 mx-2">
                  <AiFillApple color="white" fontSize={26} />
                </div>
                <div className="h-4 mx-3 w-0.5 bg-slate-400 max-[666px]:hidden"></div>
              </div>
              <div className="text-sm text-white/60 capitalize mx-3 font-semibold home_banner_more_company ">
                <span className="text-white/80 mr-1 underline">30+</span> top
                company pros unite for another stellar venture.
              </div>
            </div>
          </div>

          <div
            className="w-full my-4 py-3 text-slate-900"
            style={{
              fontFamily: `'Rethink Sans', sans-serif`,
            }}
          ></div>
        </div>
      </div>

      <ProductShowCase />
    </div>
  );
}

export default Homebanner;
