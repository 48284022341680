import React, { useState } from "react";
import Homebanner from "../Components/Home/Homebanner";
import SiteBenefit from "../Components/Home/SiteBenefit";
import Assessment from "../Components/Home/Assessment";
import Carreer from "../Components/Home/Carreer";
import BlogContainer from "../Components/Home/BlogContainer";
import Footer from "../Components/Common/Footer";
import { Helmet } from "react-helmet";
import { Faq } from "../Data/dataArray";

function Home() {
  // const [faqs, setFaqs] = useState([
  //   {
  //     question: "What is Skillkart.app?",
  //     answer:
  //       "Skillkart.app is a comprehensive platform designed to help individuals unlock their full potential and excel in job interviews within just 30 days. We offer personalized mentorship, practice sessions, and insights to enhance interview performance.",
  //   },
  //   {
  //     question: "What programs does Skillkart.app offer?",
  //     answer:
  //       "We offer a range of programs tailored to various academic backgrounds and career aspirations, including B.Tech, MBA, Digital Marketing, and more. Each program includes practice sessions with industry mentors, personalized feedback, and insights into academic preparation.",
  //   },
  //   {
  //     question: "How can I become a mentor on Skillkart.app?",
  //     answer:
  //       "If you're an experienced professional interested in becoming a mentor, you can join our community by visiting the 'Join as mentor' section on our website.",
  //   },
  //   {
  //     question: "How do I sign up for Skillkart.app?",
  //     answer:
  //       "Signing up for Skillkart.app is easy and free. Simply click on the 'Sign up free' button on our website and follow the instructions to create your account.",
  //   },
  //   {
  //     question: "How does the interview preparation program work?",
  //     answer:
  //       "Our interview preparation program is designed to transform you into a confident and competent interviewee within 30 days. You'll receive personalized mentorship, practice sessions, and insights to help you succeed in both HR and technical rounds.",
  //   },
  //   {
  //     question: "What is included in the assessment process?",
  //     answer:
  //       "Our assessment process includes resume analysis, behavior skill analysis, and technical skill analysis. These assessments provide valuable feedback and personalized growth strategies to enhance your interview preparation.",
  //   },
  //   {
  //     question: "How do I choose a mentor on Skillkart.app?",
  //     answer:
  //       "You can browse through our list of experienced mentors and select the one that best fits your preferences and career goals. Each mentor offers personalized guidance to help you succeed.",
  //   },
  //   {
  //     question: "What career options are available on Skillkart.app?",
  //     answer:
  //       "We offer career options such as front-end developer and back-end developer, along with resources to help you explore other technical fields. Our goal is to support your career aspirations and help you achieve success.",
  //   },
  //   {
  //     question: "Can I enroll in multiple programs simultaneously?",
  //     answer:
  //       "Yes, you can enroll in multiple programs based on your interests and career goals. Each program offers unique benefits and insights to enhance your skills and interview preparation.",
  //   },
  //   {
  //     question: "How can I contact Skillkart.app for further assistance?",
  //     answer:
  //       "If you have any questions or need assistance, you can reach out to us through the 'Contact Us' section on our website. We're here to support you on your journey to success.",
  //   },
  // ]);
  const steps = [
    {
      number: 1,
      title: "Choose Your Program",
      description:
        "Explore our range of comprehensive programs tailored to suit various academic backgrounds and career aspirations, including B.Tech, MBA, Digital Marketing, and more.",
    },
    {
      number: 2,
      title: "Shortlist Your Preferred Mentor",
      description:
        "Browse through our list of experienced mentors and select the one that best fits your preferences and career goals.",
    },
    {
      number: 3,
      title: "Begin Your One-on-One Mentorship",
      description:
        "Embark on a personalized and long-term mentorship journey with your selected mentor. Receive tailored guidance, practice sessions, and valuable insights to excel in your interview preparation.",
    },
  ];

  return (
    <div
      className=""
      style={{
        fontFamily: `'Rethink Sans', sans-serif`,
      }}
    >
      <Helmet>
        <title>
          SkillKart: Be Placement-ready with the top industry mentors
        </title>
        <meta name="description" content="" />
        <meta
          property="og:title"
          content="SkillKart: Be Placement-ready with the top industry mentors"
        />
        <meta
          property="og:description"
          content="Skillkart provides Placement interview preparation with 1:1 doubt support, Qualified and Experienced mentors, Resume Building Guidance, and Technical support"
        />
        <meta
          property="og:image"
          content="https://www.skillkart.app/static/media/log.31e5f9e96bf89941fe15.jpeg"
        />
        <meta
          property="twitter:title"
          content="SkillKart: Be Placement-ready with the top industry mentors"
        />
        <meta property="og:type" content="website" />
        <meta
          property="twitter:description"
          content="Skillkart.app | Be mentored and trained with India's largest Mentor community for your dream job."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Homebanner />
      <div className="py-16 px-16 max-[666px]:px-6 max-sm:px-2">
        <div className="flex flex-col items-start px-2">
          <div className=" border-2 border-black/60 rounded-full text-black  py-2.5 px-7 font-bold bg-[#f7dc6f]">
            How to start
          </div>
          <div className="text-6xl font-bold mt-4 w-7/12 max-lg:w-full">
            Get Started in Three Easy Steps:
          </div>
        </div>
        <div className="flex items-center justify-start flex-col py-16 px-4 max-md:px-0">
          <div className="w-full flex items-start max-[1104px]:flex-col">
            <div className="w-1/3 px-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full">
              <div className="border border-black/40 pl-7 pr-3 h-72 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                <div className="flex items-start justify-center mt-4 flex-col">
                  <div className="w-12 h-12 bg-black flex items-center justify-center rounded-full text-white">
                    1
                  </div>
                  <div className="mt-4">
                    <div className="font-bold text-3xl">
                      Choose Your Program
                    </div>
                  </div>
                </div>
                <div className="mx-1 mt-2 text-sm">
                  Explore our range of comprehensive programs tailored to suit
                  various academic backgrounds and career aspirations, including
                  B.Tech, MBA, Digital Marketing, and more.
                </div>
              </div>
            </div>
            <div className="w-1/3 px-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full">
              <div className="border border-black/40 pl-7 pr-3 h-72 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                <div className="flex items-start mt-4 flex-col">
                  <div className="w-12 h-12 bg-black flex items-center justify-center rounded-full text-white">
                    2
                  </div>
                  <div className="mt-4">
                    <div className="font-bold text-3xl">
                      Shortlist Your Preferred Mentor
                    </div>
                  </div>
                </div>
                <div className="mx-1 mt-2 text-sm">
                  Browse through our list of experienced mentors and select the
                  one that best fits your preferences and career goals
                </div>
              </div>
            </div>
            <div className="w-1/3 px-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full">
              <div className="border border-black/40 pl-7 pr-3 h-72 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                <div className="flex items-start mt-4 flex-col">
                  <div className="w-12 h-12 bg-black flex items-center justify-center rounded-full text-white">
                    3
                  </div>
                  <div className="mt-4">
                    <div className="font-bold text-3xl">
                      Begin Your One-on-One Mentorship
                    </div>
                  </div>
                </div>
                <div className="mx-1 mt-2 text-sm">
                  Embark on a personalized and long-term mentorship journey with
                  your selected mentor. Receive tailored guidance, practice
                  sessions, and valuable insights to excel in your interview
                  preparation.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SiteBenefit />
      <Assessment />
      <Carreer />
      <BlogContainer />
      <div className="w-full flex items-center flex-col py-20 max-md:px-3">
        <div className="text-black text-5xl font-bold">FAQs</div>
        <div className="w-2/3 mt-16 max-lg:w-4/5 max-md:w-full ">
          {Faq.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

const FAQItem = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="py-3 border border-black/20 my-4 px-6 shadow rounded-md max-lg:py-1 ">
      <div
        className="flex items-center justify-between py-3 "
        onClick={toggleExpand}
      >
        <h3 className="text-2xl font-medium max-lg:text-xl ">{question}</h3>
        <span className={`icon ${expanded ? "open" : "closed"} `}>&#9660;</span>
      </div>
      {expanded && <p className="answer text-base max-md:pb-4">{answer}</p>}
    </div>
  );
};
