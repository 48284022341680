import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import Header from "../../Components/Common/Header";
import { Faq } from "../../Data/dataArray";
import { IoIosMail } from "react-icons/io";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../Components/Common/Footer";
function FaqPage() {
  const [pageFaqs, setPageFaqs] = useState(Faq);

  const handleSearch = (e) => {
    if (e.target.value) {
      return setPageFaqs(
        pageFaqs.filter((faq) =>
          faq.question.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }else{
        setPageFaqs(Faq)
    }
  };
  return (
    <div>
      <Header />
      <div className="m-2 mt-3">
        <div className="w-full rounded-2xl bg-white pt-24 flex items-center flex-col max-md:px-4">
          <div className="text-6xl text-black font-bold text-center max-md:text-4xl">
            How can we help you?
          </div>
          <div className="mt-3 text-lg text-center w-3/5 text-black/70 max-md:w-full">
            Welcome to our FAQ section!Here you'll find answers to common
            questions about our products. If you have a question that isn't
            addressed here, feel free to reach out to us directly.
          </div>
          <div className="w-3/5 h-14 border border-black/20 bg-[#fdffda] rounded-md max-md:w-full my-6 flex items-center">
            <div className="px-6">
              <CiSearch color="black" fontSize={26} />
            </div>
            <input
              placeholder="Describe your issue"
              onChange={handleSearch}
              className="w-full h-full outline-none rounded-md text-black bg-transparent"
            />
          </div>
        </div>
        <div className="w-full flex items-start px-20 mt-14 max-[870px]:px-6 max-md:flex-col max-sm:mt-8">
          <div className="w-2/3 max-md:w-full">
            {pageFaqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
          <div className="w-1/3 border-l border-l-black/20 pl-8 max-sm:w-full max-sm:pl-0 max-md:mt-16 max-md:border-l-0">
            <div>Contact us via</div>
            <div className="flex items-center  my-3">
              <div className="mr-2">
                <IoIosMail size={16} />
              </div>
              <div className="underline text-black text-base">
                info@skillkart.app
              </div>
            </div>
            <div className="flex items-start flex-col">
              <Link
                to="https://www.facebook.com/skillkart.appM"
                target="_blank"
                className="flex items-center my-2"
              >
                <div className="flex items-center mr-2 justify-center  rounded-full">
                  <FaFacebookF fontSize={16} color="rgba(0,0,0,0.8)" />
                </div>
                <div className="underline text-black text-base">Facebook</div>
              </Link>
              <Link
                to="https://www.linkedin.com/company/skillkart-app/about/"
                target="_blank"
                className="flex items-center my-2"
              >
                <div className="flex items-center mr-2 justify-center  rounded-full">
                  <FaLinkedinIn fontSize={16} color="rgba(0,0,0,0.8)" />
                </div>
                <div className="underline text-black text-base">LinkedIn</div>
              </Link>
              <Link
                to="https://www.instagram.com/skillkart.app/"
                target="_blank"
                className="flex items-center my-2"
              >
                <div className="flex items-center mr-2 justify-center  rounded-full">
                  <FaInstagram fontSize={16} color="rgba(0,0,0,0.8)" />
                </div>
                <div className="underline text-black text-base">Instagram</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FaqPage;

const FAQItem = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="py-2 my-1 px-6 rounded-md max-md:px-0">
      <div
        className="flex items-center justify-start py-3 cursor-pointer"
        onClick={toggleExpand}
      >
        <span
          className={`icon ${expanded ? "open" : "closed"} mr-2 max-md:hidden`}
        >
          &#9660;
        </span>
        <h3 className="text-lg font-normal max-lg:text-xl  underline  text-blue-800 max-sm:text-base">
          {question}
        </h3>
      </div>
      {expanded && <p className="answer text-lg max-md:pb-4">{answer}</p>}
    </div>
  );
};
