import React, { useState } from "react";
import resume from "../../Assets/images/resumeanalysis.svg";
import behaviour from "../../Assets/images/behaviour.svg";
import Technology from "../../Assets/images/Technology.svg";

function Assessment() {
  const [assessments, setAssessments] = useState([
    {
      title: "Resume analysis",
      active: true,
      image: resume, // Make sure 'resume' is correctly imported or defined
      description: `A resume is a very important part of the placements or Interview
                  process. With a resume, you speak to your prospects of job search
                  before even meeting them. That's why a well-written Resume
                  increases your chances to appear for one to one interview and beat
                  the competition. Our resume analysis gives you an insight into
                  writing a strong and effective synopsis of your entire journey.`,
    },
    {
      title: "Behaviour Skill analysis",
      active: false,
      image: behaviour, // Make sure 'behaviour' is correctly imported or defined
      description:
        "For a successful career, it's important to learn how to engage with people, despite being in any field of the Job one has to always deal with people may be colleagues, superiors, or customers. It's important to learn and master the Emotional quotient. Our programs let you know how to present yourself in life events, tricky situations, and most importantly how to face different types of interviews and interviewers.",
    },
    {
      title: "Technical skill analysis",
      active: false,
      image: Technology, // Make sure 'Technology' is correctly imported or defined
      description:
        "While preparing for interviews, it's important to have a vision for long-term career goals, To achieve those goals you need to have a strong grip on the fundamentals of Technicals, what are recent trends in corporates, and how industry expectations are modeling towards the future. Our Technical analysis gives you clarity on your preparation and how to level up your preparation to achieve your goals.",
    },
  ]);

  return (
    <div className="bg-[white] w-full pb-12 px-16 flex flex-col items-center max-md:px-4 max-sm:px-2">
      <div className="bg-[#e0e9ff] rounded-md text-lg py-2 px-7  font-semibold mt-3">
        Assessment
      </div>

      <div className="text-5xl mt-4 pb-4 pt-1 text-center max-sm:w-full font-bold w-9/12 max-md:w-11/12 max-md:text-3xl">
        Assess skills, knowledge, and progress. Receive feedback for
        personalized growth strategies.
      </div>
      <div className="w-full flex flex-wrap mt-20">
        <div className="w-1/2 flex min-h-0 rounded-md relative px-2 max-[900px]:w-full max-[900px]:mb-4">
          <div className="w-full border-black/40 border rounded-md relative ">
            <div className="h-52 absolute py-8 w-1/2 right-0 top-4">
              <img
                src={resume}
                className="w-full h-full object-bottom object-contain"
                alt=""
              />
            </div>
            <div className="py-10 px-7 ">
              <div className="w-14 h-14 bg-black rounded-full text-white flex items-center justify-center font-bold text-xl">
                1
              </div>
              <div className="text-2xl py-2 font-bold mt-14 bg-white">
                Resume analysis
              </div>
              <div className=" text-justify text-black/90 text-xl bg-white z-10 max-sm:text-base">
                A resume is a very important part of the placement or Interview
                process. With a resume, you speak to your prospects for a job
                search before even meeting them. That's why a well-written
                Resume increases your chances of appearing for one-on-one
                interview and beating the competition. Our resume analysis gives
                you an insight into writing a strong and effective synopsis of
                your entire journey.
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 flex min-h-0 rounded-md relative px-2 max-[900px]:w-full max-[900px]:mb-4">
          <div className="w-full border-black/40 border rounded-md relative ">
            <div className="h-52 absolute py-8 w-1/2 right-0 top-4">
              <img
                src={behaviour}
                className="w-full h-full object-bottom object-contain"
                alt=""
              />
            </div>
            <div className="py-10 px-7 ">
              <div className="w-14 h-14 bg-black rounded-full text-white flex items-center justify-center font-bold text-xl">
                2
              </div>
              <div className="text-2xl py-2 font-bold mt-14 bg-white">
                Behaviour Skill analysis
              </div>
              <div className="text-justify text-black/90 text-xl bg-white z-10 max-sm:text-base">
                For a successful career, it's important to learn how to engage
                with people, despite being in any field of the Job one has to
                always deal with people colleagues, superiors, or customers.
                It's important to learn and master the Emotional quotient. Our
                programs let you know how to present yourself in life events,
                tricky situations, and most importantly how to face different
                types of interviews and interviewers.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex my-7 mx-4 border border-black/40 rounded-md max-[900px]:flex-col relative">
          <div className="h-96 w-1/2 max-[900px]:h-40 max-[900px]:absolute max-[900px]:w-full right-0 top-4 max-[900px]:bg-transparent py-8  bg-black/10 max-[900px]:py-0 max-[900px]:object-right max-[900px]:px-8">
            <img
              src={resume}
              className="w-full h-full object-bottom object-contain max-[900px]:object-right"
              alt=""
            />
          </div>
          <div className="py-10 px-7 w-1/2 max-[900px]:w-full">
            <div className="w-14 h-14 bg-black rounded-full text-white flex items-center justify-center font-bold text-xl">
              3
            </div>
            <div className="text-2xl py-2 font-bold max-[900px]:mt-14">
              Technical skill analysis
            </div>
            <div className=" text-justify text-black/90 text-xl max-sm:text-base">
              While preparing for interviews, it's important to have a vision
              for long-term career goals, To achieve those goals you need to
              have a strong grip on the fundamentals of Technicals, what are
              recent trends in corporates, and how industry expectations are
              modeling towards the future. Our Technical analysis gives you
              clarity on your preparation and how to level up your preparation
              to achieve your goals.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assessment;
