import React from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <Header />
      <div className="bg-[#ffd84d] w-full py-14 flex flex-col items-center">
        <div className="w-full max-sm:w-full flex items-center flex-col leading-tight">
          <div className="text-center text-[200px] max-md:text-5xl font-bold flex items-center">
            4
            <div className="h-36 w-20 rounded-full bg-black p-6 mx-4">
              <div className="h-full w-full bg-white rounded-full"></div>
            </div>
            4
          </div>
          <div className="text-3xl font-semibold max-sm:text-2xl text-center max-md:mt-6">
            Page Not Found
          </div>
          <div className="text-center max-sm:text-center text-base w-6/12 mt-2 max-md:w-full max-md:px-2">
            {" "}
            Oops! The page you are looking for does not exist. You may have
            mistyped the URL or the page has been moved.
          </div>
          <div className="pt-10">
            <Link to="/">
              <div className="border-2 bg-black text-white border-black flex rounded-md px-6 items-center max-sm:flex-wrap h-14  w-full">
                Back to home
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;
