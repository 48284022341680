import axios from "axios";

export const _GetBlogs = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        "https://www.skillkart.app/api/blog/Blog.php"
      );
      resolve(result.data.data);
    } catch (err) {
      reject(err);
    }
  });
};
