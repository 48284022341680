import React from "react";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RxHamburgerMenu } from "react-icons/rx";
import { _scrolltoElement } from "../../Script/OtherFunction";

function Header() {
  const user = useSelector((state) => state?.user);
  const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);
  return (
    <div
      className="py-4 w-full px-24 z-10 max-[666px]:px-6 max-[666px]:my-3"
      style={{
        fontFamily: `'Rethink Sans', sans-serif`,
      }}
    >
      {openBurgerMenu ? (
        <div className="w-full h-screen fixed bg-white top-0 bottom-0 right-0 left-0 z-50 px-6 py-4 my-3">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center w-full justify-between flex-col">
              <div className="flex items-center justify-between w-full ">
                <Link to="/">
                  {/* <img src={Logo} alt="app_logo" className="w-32" /> */}
                  <span className="font-bold text-2xl flex items-center text-black">
                    Skillkart.app
                    <div className="h-5 w-1.5  mx-3 rounded-full bg-white"></div>
                    {/* <span className="text-xl text-[#EA9215]"> Blogs</span> */}
                  </span>{" "}
                </Link>
                <div
                  className="border border-black p-2 rounded-lg"
                  onClick={() => setOpenBurgerMenu(false)}
                >
                  <IoMdClose fontSize={21} />
                </div>
              </div>

              <div className="flex items-center flex-col py-8 px-4 w-full">
                <div className="flex items-start justify-between w-full flex-col my-7">
                  <div className="mx-2 flex items-start cursor-pointer flex-col">
                    <Link to="/programs">
                      <div
                        // onClick={scrolltopurchase}
                        className=" py-1 text-black/90 hover:text-slate-900 text-base font-semibold my-5"
                      >
                        Programs
                      </div>
                    </Link>

                    <Link to="/blogs">
                      <div className=" py-1 text-black/90 hover:text-slate-900 text-base font-semibold my-5">
                        Blogs
                      </div>
                    </Link>
                    <Link to="/becomeamentor">
                      <div className=" py-1 text-black/90 hover:text-slate-900 text-base font-semibold my-5">
                        Become a mentor
                      </div>
                    </Link>
                  </div>
                  {user ? (
                    <div className="flex items-center justify-end relative">
                      <div className="w-10 h-10 cursor-pointer rounded-full flex items-center justify-center border border-black">
                        {user?.photo ? (
                          <>
                            {user.role === "user" ? (
                              <Link to="/dashboard">
                                <img
                                  src={user?.photo}
                                  alt="user_image"
                                  className="w-10 object-cover object-center h-10 rounded-full"
                                />
                              </Link>
                            ) : (
                              <Link to="/dashboard/mentor">
                                <img
                                  src={user?.photo}
                                  alt="user_image"
                                  className="w-10 object-cover object-center h-10 rounded-full"
                                />
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link to="/dashboard">
                            <div
                              className="w-9 h-9 rounded-full"
                              style={{
                                background: `linear-gradient(90deg, #FDBB2D 0%, #22C1C3 100%)`,
                              }}
                            ></div>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start w-full flex-col my-5">
                      <Link to="/Account/Login" className="w-full">
                        <div className="px-6 py-2.5 w-full text-black/80 rounded-md hover:text-slate-900 text-base border border-black/80">
                          Login
                        </div>
                      </Link>
                      <Link to="/Account/Signup" className="w-full">
                        <div className="py-2.5 mt-5 rounded-md px-6 font-bold bg-black cursor-pointer text-base text-white">
                          Sign up free
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center w-full justify-between">
          <Link to="/">
            {/* <img src={Logo} alt="app_logo" className="w-32" /> */}
            <span className="font-bold text-2xl flex items-center text-black">
              Skillkart.app
              <div className="h-5 w-1.5  mx-3 rounded-full bg-white"></div>
              {/* <span className="text-xl text-[#EA9215]"> Blogs</span> */}
            </span>{" "}
          </Link>

          <div className="flex items-center max-[1045px]:hidden">
            <div className="flex items-center justify-between w-full">
              <div className="mx-2 flex items-center cursor-pointer">
                <Link to="/programs">
                  <div
                    // onClick={scrolltopurchase}
                    className="mx-3 px-3 py-1 text-black/90 hover:text-slate-900 text-base font-semibold"
                  >
                    Programs
                  </div>
                </Link>

                <Link to="/Blogs">
                  <div className="mx-3 px-3 py-1 text-black/90 hover:text-slate-900 text-base font-semibold">
                    Blogs
                  </div>
                </Link>
                <Link to="/becomeamentor">
                  <div className="mx-3 px-3 py-1 text-black/90 hover:text-slate-900 text-base font-semibold header_become_mentor">
                    Become a mentor
                  </div>
                </Link>
              </div>
              {user ? (
                <div className="flex items-center justify-end relative">
                  <div className="w-10 h-10 cursor-pointer rounded-full flex items-center justify-center border border-black">
                    {user?.photo ? (
                      <>
                        {user.role === "user" ? (
                          <Link to="/dashboard">
                            <img
                              src={user?.photo}
                              alt="user_image"
                              className="w-10 object-cover object-center h-10 rounded-full"
                            />
                          </Link>
                        ) : (
                          <Link to="/dashboard/mentor">
                            <img
                              src={user?.photo}
                              alt="user_image"
                              className="w-10 object-cover object-center h-10 rounded-full"
                            />
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link to="/dashboard">
                        <div
                          className="w-9 h-9 rounded-full"
                          style={{
                            background: `linear-gradient(90deg, #FDBB2D 0%, #22C1C3 100%)`,
                          }}
                        ></div>
                      </Link>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="flex items-center justify-end w-72 header_auth_container"
                  style={{
                    fontFamily: `'Open Sans', sans-serif`,
                  }}
                >
                  <Link to="/Account/Login">
                    <div className="mx-3 px-6 py-2.5 text-black/80 rounded-md hover:text-slate-900 text-base border border-black/80">
                      Login
                    </div>
                  </Link>
                  <Link to="/Account/Signup">
                    <div className="py-2.5 mx-3 rounded-md px-6 font-bold bg-black cursor-pointer text-base text-white">
                      Sign up free
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div
            className="border border-black p-2 rounded-lg max-[1045px]:block hidden"
            onClick={() => {
              setOpenBurgerMenu(true);
            }}
          >
            <RxHamburgerMenu fontSize={21} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
