import api from "./api";

export const _apiCalls = (url, data, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api
        .post(url, data, {
          headers: {
            authorization: authToken,
            "ngrok-skip-browser-warning": "true",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
export const _apiGetCalls = (url, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api
        .get(url, {
          headers: {
            authorization: authToken,
            "ngrok-skip-browser-warning": "true",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
