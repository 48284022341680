import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BlogContainer() {
  const blog = useSelector((state) => state.Blogs);

  return (
    <div className="py-24 bg-black px-10 max-sm:px-2">
      <div className="text-center text-white text-3xl pb-10 b-join-title">
        Join our Community
      </div>
      <div className="px-4">
        <div
          className="pt-4 text-[#ffc700] text-sm font-semibold"
          style={{
            letterSpacing: "1.5px",
          }}
        >
          Interview preparation
        </div>
        <div className="text-white/80 text-2xl">
          Preparing for an interview is crucial to increase your chances of
          success.
        </div>
      </div>
      <div className="py-8 flex w-full px-2 justify-center b-container-items flex-wrap">
        {blog?.slice(0, 3)?.map((blogs) => (
          <div
            className="h-full mx-3 cursor-pointer b-m-container"
            onClick={() => {
              window.location.assign(
                `/blog/${blogs.blog_title.split(" ").join("-")}&pageid=${
                  blogs.id
                }`
              );
            }}
          >
            <div
              class="w-full h-1/3 rounded-lg"
              style={{
                height: "210px",
              }}
            >
              <img
                src={blogs?.blog_image}
                width="100%"
                alt="blog_image"
                className="h-full object-cover rounded-lg"
              />
            </div>
            <div class="my-2 items-start text-white">
              <div class="font-medium text-xl">{blogs?.blog_title}</div>
              <div class=" text-white/80 text-base my-1">
                {blogs?.blog_content?.slice(0, 70) + "..."}
              </div>
              {/* <a onClick={handleblog}> Read full Blog</a> */}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center">
        <Link to="/Blogs">
          <div
            className="bg-white/90 rounded-lg text-lg flex items-center justify-center"
            style={{
              width: "250px",
              height: "55px",
            }}
          >
            Read more articles
          </div>
        </Link>
      </div>
    </div>
  );
}

export default BlogContainer;
