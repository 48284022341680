import axios from "axios";
import api from "./api";
import { useDispatch } from "react-redux";
import { User } from "../Containers/Redux/Selectors";
import { _apiCalls } from "./ApiCalls";

export const _fetchProducts = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await api.get("/product", {
        headers: { "ngrok-skip-browser-warning": "true" },
      });
      resolve(result.data.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const _handlePurchaseStatus = async (
  order_id,
  payment_id,
  user,
  status,
  product
) => {
  return new Promise(async (resolve, reject) => {
    const date = new Date();
    let d = date.getDate();
    let m = date.getMonth();
    let year = date.getFullYear();

    let data = {
      user_id: user._id,
      course: product.product_name,
      course_id: product._id,
      price: product.discount_pricing,
      username: user.Name,
      email: user.Email,
      status: status,
      order_id: order_id,
      payment_id: payment_id,
      Purchasedate: `${year}-${m + 1}-${d}`,
    };
    await _apiCalls("/paymentstatus", data)
      .then((result) => {
        resolve(result);
      })
      .catch(() => {
        reject();
      });
  });
};

export const _PurchaseCourse = async (product, user) => {
  let redirectUrl = new URLSearchParams(window.location.search).get("redirect");
  if (user) {
    let data = {
      amount: parseInt(product.discount_pricing),
      email: user?.Email,
    };
    await _apiCalls("/payment", data)
      .then((result) => {
        const options = {
          key: "rzp_live_RQzzWKaCrOClbT", // Enter the Key ID generated from the Dashboard
          amount: product.discount_pricing, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Skillkart.app",
          description: product.product_name,
          image: "https://skillkart.app/favicon.ico",
          order_id: result.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response) {
            await _handlePurchaseStatus(
              response.razorpay_order_id,
              response.razorpay_payment_id,
              user,
              "success",
              product
            )
              .then((result) => {
               
                window.location.assign(
                  `/paymentSuccessfull?transcation_id=${result.request._id}${
                    redirectUrl && "&redirect=" + redirectUrl
                  }`
                );
              })
              .catch((err) => {
                console.log(err);
                // window.location.reload();
              });
          },
          prefill: {
            name: user.name,
            email: user.Email,
            contact: user.phone,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", async function (response) {
          await _handlePurchaseStatus(
            response.razorpay_order_id,
            response.razorpay_payment_id,
            user,
            "Failed",
            product
          );
          window.location.reload();
        });
        rzp1.open();
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    window.location.assign("/account/login");
  }
};
